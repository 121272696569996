import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import {useEffect, useState} from "react";
import  {isLoggedIn} from "../utils/auth.service";
import {graphql, navigate} from "gatsby";
import authHeader from "../utils/auth-header";
import LoadingSpinner from "../components/loading-spinner";
import toast, {Toaster} from 'react-hot-toast';
import {useTranslation} from "gatsby-plugin-react-i18next";
import UsersList from "../components/users/list";
import PhoneInput from 'react-phone-number-input'

const Users = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [inputPhone, setInputPhone] = useState("");
    const [role, setRole] = useState('user');
    const [users, setUsers] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(false);




    useEffect(() => {

        if (!isLoggedIn()) {
            return navigate('/');
        }
        loadUsers();


    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            setSubmitDisabled(true);
            e.target.disabled = true

            if(inputPhone === ''){
                toast.error('cant-be-empty!');
                setSubmitDisabled(false);
                return;
            }
            

            const requestOptions = {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify({
                    phone_with_code: inputPhone,
                    role: role,

                })
            };


            fetch(process.env.WASHCAR_API_URL + "/business/add-user", requestOptions)
                .then(response => response.json()) // parse JSON from request
                .then(resultData => {

                    if (resultData.status === 'Error') {
                        toast.error(resultData.alert.text)
                    } else {

                        //@todo can with out fetch????
                        toast.success(resultData.alert.text);
                        loadUsers();
                        setInputPhone("");
                    }

                    setSubmitDisabled(false);


                }) // set data for the number of stars

        } catch (err) {
            console.log(err);
        }

    };


    const loadUsers = () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        setLoading(true);

        fetch(process.env.WASHCAR_API_URL + "/business/company-added-users/", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {

                setLoading(false);
                setUsers(resultData.data);
            }) //
    }





    return (
        <Layout>

            <Seo title={t('users')}/>

            <h1 className="title">{t('users')}</h1>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {loading ? <div className="pos-center"><LoadingSpinner/></div> : <div>

                {users.length === 0 ? <p className={'box notification is-warning'}> {t('no_addresses_found_text')}</p> :
                    <UsersList users={users} />}


            </div>}


            <div className="card mt-5">

                <header className="card-header">
                    <p className="card-header-title">
                        {t('add_new_user')}
                    </p>

                </header>

                <div className="card-content">

                    <div className="content">
                        <form onSubmit={handleSubmit}>





                            <div className="field">

                                <div className="control">
                                    <PhoneInput
                                        placeholder={t('enter_phone_number')}
                                        value={inputPhone}
                                        defaultCountry="LV"
                                        onChange={setInputPhone}/>
                                </div>
                            </div>


                            <div className="field">

                                <div className={'notification'}>
                                    <ul>
                                        <li>{t('role_user_description')}</li>
                                        <li>{t('role_admin_description')}</li>
                                    </ul>

                                    <div className="control">
                                        <label className="radio">
                                            <input value={'user'}  onChange={e => setRole(e.target.value)}  type="radio" name="role" checked />
                                            {t('user')}
                                        </label>
                                        <label className="radio">
                                            <input value={'admin'}   onChange={e => setRole(e.target.value)} type="radio" name="role" />
                                            {t('admin')}
                                        </label>
                                    </div>


                                </div>



                            </div>



                            <div className="field is-grouped">
                                <div className="control">
                                    <button disabled={submitDisabled} type="submit"
                                            className="button is-link">{t('add')}
                                    </button>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>


        </Layout>
    )
}

export default Users

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

/*

                <input required className="input" type="text" placeholder={t('enter_address')}
                                           value={inputPhone} onChange={(e) => setInputPhone(e.target.value)}/>
 */