
import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";


const UsersList = (props) => {

    const {t} = useTranslation();

    return (


        <div className="card">

            <header className="card-header">
                <p className="card-header-title">
                    {t('added_users')}
                </p>

            </header>

            <div className="card-content">

                <div className="content">
                    <table className="table">


                        <tbody>

                        {props.users.map(user => (
                            <tr key={user.id}>
                                <td>{user.first_name} {user.last_name} <b>+{user.phone_with_code}</b> ({user.role})</td>

                            </tr>
                        ))}


                        </tbody>
                    </table>
                </div>
            </div>
        </div>



    );
};

export default UsersList;

/*

             <thead>
                        <tr>
                            <th>{t('user')}</th>
                            <th>{t('phone')}</th>

                        </tr>
                        </thead>
 */